define("discourse/plugins/discourse-global-communities/discourse/initializers/discovery", ["exports", "@ember/object", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/preload-store", "discourse/lib/text", "discourse/models/category-list", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/all-loaded", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/custom-category-heading", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/custom-tag-heading", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/hero", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/share-your-thoughts", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/sidebar", "discourse/plugins/discourse-global-communities/discourse/components/agc/discovery/topic-list", "discourse/plugins/discourse-global-communities/discourse/helpers/urlstrip"], function (_exports, _object, _service, _rsvp, _ajax, _pluginApi, _preloadStore, _text, _categoryList, _allLoaded, _customCategoryHeading, _customTagHeading, _hero, _shareYourThoughts, _sidebar, _topicList, _urlstrip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-global-communities";
  var _default = _exports.default = {
    name: "agc-discovery",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.14.0", api => {
        modifyRouteDiscoveryCategories(api);
        modifyRouteDiscoveryCategory(api);
        modifyRouteDiscoveryLatest(api);
        modifyModelCategoryList(api);
        appendHero(api);
        appendSidebar(api);
        appendShareYourThoughts(api);
        replaceListArea(api);
        replaceTopicListBottom(api);
        replaceCategoryHeading(api);
        replaceTagHeading(api);
      });
    }
  };
  function appendHero(api) {
    api.renderInOutlet("before-list-area", _hero.default);
  }
  function appendSidebar(api) {
    api.renderInOutlet("before-list-area", _sidebar.default);
  }
  function appendShareYourThoughts(api) {
    api.renderInOutlet("discovery-list-container-top", _shareYourThoughts.default);
  }
  function replaceListArea(api) {
    api.renderInOutlet("discovery-list-area", _topicList.default);
  }
  function replaceTopicListBottom(api) {
    api.renderInOutlet("topic-list-bottom", _allLoaded.default);
  }
  function replaceCategoryHeading(api) {
    api.renderInOutlet("discovery-list-controls-above", _customCategoryHeading.default);
  }
  function replaceTagHeading(api) {
    api.renderInOutlet("discovery-list-controls-above", _customTagHeading.default);
  }
  function modifyRouteDiscoveryCategories(api) {
    api.modifyClass("route:discovery.categories", {
      pluginId: PLUGIN_ID,
      agcDiscovery: (0, _service.service)(),
      currentUser: (0, _service.service)(),
      historyStore: (0, _service.service)(),
      session: (0, _service.service)(),
      // Chooses the filter of the category page based on the site setting desktop_category_page_style even on mobile
      findCategories() {
        let filter = this.session.get("homefeed_filter");
        if (!filter) {
          switch (this.siteSettings.desktop_category_page_style) {
            case "categories_and_latest_topics_created_date":
            case "categories_and_latest_topics":
              filter = "latest";
              break;
            case "categories_and_top_topics":
              if (this.currentUser.agcFeatureFlags["hot_home_feed_enabled"]) {
                filter = "hot";
              } else {
                filter = "top";
              }
              break;
            default:
              filter = "latest";
              break;
          }
          if (filter === "hot") {
            _preloadStore.default.remove("topic_list");
          } else if (this.currentUser?.agcFeatureFlags["enable_user_option_default_sorting"]) {
            const defaultFeedSorting = this.currentUser.user_option.agc_default_home_feed_sorting;
            if (defaultFeedSorting && filter !== defaultFeedSorting) {
              // the preload topic list in the category page will not be the same as the user
              // preference, so we need to ignore it and fetch the correct data again
              _preloadStore.default.remove("topic_list");
              filter = defaultFeedSorting;
            }
          }
        }
        return this._findCategoriesAndTopics(filter);
      },
      beforeModel(transition) {
        if (!this.historyStore.isPoppedState || transition.from?.name === transition.to?.name) {
          this.session.set("homefeed_cached", false);
          // clear the cached data if the page is not in a popped state or the transition is from/to the same page
          this.session.set("homefeed_filter", null);
          this.session.set("homefeed_categories_and_topics", null);
          this.session.set("homefeed_show_more_posts", false);
          this.session.set("scroll-tracker-homefeed", null);
        } else {
          this.session.set("homefeed_cached", true);
        }
        return this._super(...arguments);
      },
      model() {
        return this._super(...arguments).then(model => {
          const circlesByCategoryId = (model.get("circles") || []).reduce((acc, circle) => {
            acc[circle.category_id] = circle;
            return acc;
          }, {});
          model.get("topics").forEach(topic => {
            if (topic.category_id) {
              topic.set("circle", circlesByCategoryId[topic.category_id]);
            }
          });

          // TODO maybe replace this with a single method passing the model as an argument
          this.agcDiscovery.gcFeatured = (model.get("gc_featured") || []).map(processFeatured);
          this.agcDiscovery.gcTopContributors = model.get("gc_top_contributors") || [];
          this.agcDiscovery.gcShareYourExpertise = (model.get("gc_share_your_expertise") || []).map(processShareYourExpertise);
          return model;
        });
      },
      _findCategoriesAndTopics(filter) {
        return (0, _rsvp.hash)({
          wrappedCategoriesList: _preloadStore.default.getAndRemove("categories_list"),
          topicsList: _preloadStore.default.getAndRemove("topic_list")
        }).then(response => {
          let {
            wrappedCategoriesList
          } = response;
          let topicsList;
          topicsList = response.topicsList && this.store.createRecord("topicList", response.topicsList);
          let categoriesList = wrappedCategoriesList?.category_list;
          if (categoriesList && topicsList) {
            topicsList.set("__homefeed_filter", filter);
            // on the very first load performance.now() will be calculated from the time the page was loaded
            topicsList.__agcAnalyticsTopicListLoadTime = performance.now();
            if (topicsList.topic_list?.top_tags) {
              this.site.set("top_tags", topicsList.topic_list.top_tags);
            }
            return _object.default.create({
              topics: topicsList.topics,
              topicList: topicsList,
              categories: _categoryList.default.categoriesFrom(this.store, wrappedCategoriesList),
              can_create_category: categoriesList.can_create_category,
              can_create_topic: categoriesList.can_create_topic,
              gc_top_contributors: topicsList.topic_list.gc_top_contributors,
              gc_share_your_expertise: topicsList.topic_list.gc_share_your_expertise,
              gc_featured: topicsList.topic_list.gc_featured,
              circles: topicsList.topic_list.circles,
              loadBefore: this._loadBefore(this.store)
            });
          }
          const loadStart = performance.now();

          // homefeed_cached == true if the page is in a popped state
          if (this.session.get("homefeed_cached")) {
            const cachedData = this.session.get("homefeed_categories_and_topics");
            const cachedTopicList = cachedData?.topicList;

            // Try to use the cached version if the topic list exists and is greater than the topics per page
            if (cachedTopicList && cachedTopicList?.__homefeed_filter === filter && (cachedTopicList.get("topics.length") || 0) > cachedTopicList.get("per_page")) {
              cachedTopicList.set("loaded", true);
              return _rsvp.Promise.resolve(cachedData);
            }
          }

          // If there is no cached data or it can't be used, make an ajax request to fetch the data from the server
          return (0, _ajax.ajax)(`/categories_and_${filter}`).then(fetchedData => {
            if (fetchedData.topic_list?.top_tags) {
              this.site.set("top_tags", fetchedData.topic_list.top_tags);
            }
            topicsList = this.store.createRecord("topicList", fetchedData);
            topicsList.set("__homefeed_filter", filter);
            // on subsequent loads the time will be calculated from time the request was sent
            topicsList.__agcAnalyticsTopicListLoadTime = performance.now() - loadStart;
            const result = _object.default.create({
              topics: topicsList.topics,
              topicList: topicsList,
              categories: _categoryList.default.categoriesFrom(this.store, fetchedData),
              can_create_category: fetchedData.category_list.can_create_category,
              can_create_topic: fetchedData.category_list.can_create_topic,
              gc_top_contributors: fetchedData.topic_list.gc_top_contributors,
              gc_share_your_expertise: fetchedData.topic_list.gc_share_your_expertise,
              gc_featured: fetchedData.topic_list.gc_featured,
              circles: fetchedData.topic_list.circles,
              loadBefore: this._loadBefore(this.store)
            });
            return result;
          });
        }).then(context => {
          // store the result in the session so it can be used on subsequent loads when the page is in a popped
          // state
          this.session.set("homefeed_categories_and_topics", context);
          this.agcDiscovery.gcTopContributors = context.gc_top_contributors;
          this.agcDiscovery.gcShareYourExpertise = (context.gc_share_your_expertise || []).map(processShareYourExpertise);
          this.agcDiscovery.gcFeatured = (context.gc_featured || []).map(processFeatured);
          return context;
        });
      }
    });
  }
  function modifyRouteDiscoveryCategory(api) {
    api.modifyClass("route:discovery.category", {
      pluginId: PLUGIN_ID,
      agcDiscovery: (0, _service.service)(),
      _retrieveTopicList() {
        return this._super(...arguments).then(topicList => {
          this.agcDiscovery.gcFeatured = (topicList.get("topic_list.gc_featured") || []).map(processFeatured);
          this.agcDiscovery.gcTopContributors = topicList.get("topic_list.gc_top_contributors") || [];
          this.agcDiscovery.gcShareYourExpertise = (topicList.get("topic_list.gc_share_your_expertise") || []).map(processShareYourExpertise);
          return topicList;
        });
      }
    });
  }
  function modifyRouteDiscoveryLatest(api) {
    api.modifyClass("route:discovery.latest", {
      pluginId: PLUGIN_ID,
      agcDiscovery: (0, _service.service)(),
      model() {
        return this._super(...arguments).then(model => {
          this.agcDiscovery.gcFeatured = (model.list?.get("topic_list.gc_featured") || []).map(processFeatured);
          this.agcDiscovery.gcTopContributors = model.list?.get("topic_list.gc_top_contributors") || [];
          this.agcDiscovery.gcShareYourExpertise = (model.list?.get("topic_list.gc_share_your_expertise") || []).map(processShareYourExpertise);
          return model;
        });
      }
    });
  }
  function modifyModelCategoryList(api) {
    api.modifyClassStatic("model:category-list", {
      pluginId: PLUGIN_ID,
      // copied from core; ideally we should have extension points in core
      // to allow stuff like this.
      list(store) {
        const getCategories = () => (0, _ajax.ajax)("/categories.json");
        return _preloadStore.default.getAndRemove("categories_list", getCategories).then(result => {
          return _categoryList.default.create({
            categories: this.categoriesFrom(store, result),
            can_create_category: result.category_list.can_create_category,
            can_create_topic: result.category_list.can_create_topic,
            gc_featured: result.category_list.gc_featured,
            gc_top_contributors: result.category_list.gc_top_contributors,
            gc_share_your_expertise: result.category_list.gc_share_your_expertise
          });
        });
      }
    });
  }
  function processFeatured(item) {
    if (item.thumbnails) {
      const sizedThumbnail = item.thumbnails.filter(t => {
        if (!t.url) {
          return false;
        }
        return t.max_width === 600 && t.max_height === 300;
      });
      if (sizedThumbnail.lastObject) {
        item.thumbnail_url = sizedThumbnail.lastObject.url;
      } else {
        item.thumbnail_url = item.thumbnails[0].url;
      }
    }
    return item;
  }
  function processShareYourExpertise(item) {
    if (item.excerpt) {
      item.excerpt = (0, _text.emojiUnescape)((0, _urlstrip.strip)(item.excerpt, {
        safeString: false
      }));
    }
    return item;
  }
});